import React from "react";
import bg from "../../Images/specialbg.png";
function Special() {
  const items = [
    {
      day: "Tuesday",
      menu: "Tito’s Handmade Vodka | 4",
    },
    {
      day: "Wednesday",
      menu: "Jameson | 4 Tullamore Dew | 4",
    },
    {
      day: "Thursday",
      menu: "Well Cocktails | 3",
    },
    {
      day: "Friday",
      menu: "Bulleit Bourbon | 4 Bulleit Rye | 4",
    },
    {
      day: "Saturday",
      menu: "Fireball | 4 Skrewball | 4",
    },
    {
      day: "Sunday",
      menu: "High Bank Whiskey War | 4",
    },
  ];
  return (
    <div
      className="pt-10 pb-10   md:bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="p-2 md:p-4 items-center justify-center lg:items-start lg:ps-[200px] lg:py-[70px] flex flex-col gap-5">
        <h1 className="text-xl md:text-2xl lg:text-3xl ">Daily Special</h1>
        <div className="grid md:grid-cols-2 gap-4 lg:w-[570px]">
            {items.map((item,i)=>{
                return (
                    <div key={i} className="flex  justify-center flex-col">
                     
                        <h5 className="text-xl">* {item.day}</h5>
                        <p className=" text-[#AFAFAF]">{item.menu}</p>
                    </div>
                )
            })}
        </div>
      </div>
    </div>
  );
}

export default Special;
