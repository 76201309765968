import React, { useState } from "react";
import "../css/EventRequest.css";
import emailjs from "emailjs-com";

import EventRequestImage from "../Images/EventRequestImage.jpeg";

const EventRequest = () => {
  const [errors, setErrors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const validateEmailAddress = (email) => {
    const emailRegex =
      /^[^\s@]+@(gmail\.com|outlook\.com|yahoo\.com|hotmail\.com)$/i;
    return emailRegex.test(email);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    eventNature: "",
    startDate: "",
    numberOfPeople: "",
    startTime: "",
    endTime: "",
    additionalInfo: "",
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "tel") {
      let input = value.replace(/\D/g, ""); // Remove all non-numeric characters

      if (input.startsWith("1")) {
        input = input.substring(1); // Remove the leading '1' since we'll add it later
      }

      let formattedNumber = "";
      const cursorPosition = e.target.selectionStart; // Get the current cursor position
      const lastChar = value[cursorPosition - 1]; // Get the last character before the cursor

      // Format the number
      if (input.length > 0) {
        formattedNumber += "+1 "; // Add country code

        if (input.length > 0) {
          formattedNumber += "(" + input.slice(0, 3); // Area code

          if (input.length > 3) {
            formattedNumber += ") " + input.slice(3, 6); // First three digits
          }
          if (input.length >= 6) {
            formattedNumber += "-" + input.slice(6, 10); // Last four digits
          }
        }
      }

      // Handle backspace or delete
      if (lastChar === ")" || lastChar === "-" || lastChar === " ") {
        formattedNumber = formattedNumber.slice(0, -1); // Remove last character if backspace is pressed
      }

      setFormData({
        ...formData,
        phone: formattedNumber.trim(),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
    // Clear the error for the specific field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNumberDigits = formData.phone.replace(/\D/g, "");

    const newErrors = {};
    if (!formData.firstName)
      newErrors.firstName = "Please enter your First Name";
    if (!formData.lastName) newErrors.lastName = "Please enter your Last Name";
    if (!formData.email) {
      newErrors.email = "Please enter your Email";
    } else if (!validateEmailAddress(formData.email)) {
      newErrors.email =
        "Please enter a valid email address with a supported domain.";
    }
    if (!formData.phone) {
      newErrors.phone = "Please enter your Phone Number";
    } else if (phoneNumberDigits.length < 10) {
      newErrors.phone = "Please enter a valid 10-digit Mobile Number";
    }
    if (!formData.eventNature)
      newErrors.eventNature = "Please enter your Event Type";
    if (!formData.startDate) newErrors.startDate = "Please enter Start Date";
    if (!formData.numberOfPeople)
      newErrors.numberOfPeople = "Please enter Number of People";
    if (!formData.startTime) newErrors.startTime = "Please enter Start Time";
    if (!formData.endTime) newErrors.endTime = "Please enter End Time";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Send email using EmailJS
    emailjs
      .send(
        "service_b6o7wrc",
        "template_aktmjf9",
        formData,
        "XiWvh6-JQqypfl530"
      )
      .then((response) => {
        console.log("Email sent successfully:", response.status, response.text);
        setSuccessMessage("Your request has been sent successfully.");

        // Reset formData to initial values
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          eventNature: "",
          startDate: "",
          numberOfPeople: "",
          startTime: "",
          endTime: "",
          additionalInfo: "",
        });

        // Clear error and success messages
        setSuccessMessage("");
        setShowSuccessModal(true);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });

    setFormData("");
  };

  return (
    <div className="event-request-container">
      <div className="top-image-section">
        <img
          src={EventRequestImage}
          alt="Event Section Image"
          className="full-width-image"
        />
        <div className="text-overlay">
          <h1>Event Request</h1>
          <p>
            <br />
            To enter and Request Event, Please fill out the following form.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="event-request-form">
        <h2 className="form-title">Your Contact Information</h2>
        <div className="event-form-row">
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              placeholder="Enter First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <span className="error-message">{errors.firstName}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Enter Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <span className="error-message">{errors.lastName}</span>
            )}
          </div>
        </div>

        <div className="event-form-row">
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Email Address"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              name="phone"
              placeholder="Enter Phone Number"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && (
              <span className="error-message">{errors.phone}</span>
            )}
          </div>
        </div>

        <hr className="separator-line" />

        <h2 className="form-title">Your Event Details</h2>

        <div className="form-group" style={{ margin: "0 0 20px" }}>
          <label htmlFor="eventNature">
            Type of this event (e.g Birthday Party or Business Dinner)
          </label>
          <input
            type="text"
            name="eventNature"
            value={formData.eventNature}
            onChange={handleChange}
          />
          {errors.eventNature && (
            <span className="error-message">{errors.eventNature}</span>
          )}
        </div>

        <div className="event-form-row">
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
            {errors.startDate && (
              <span className="error-message">{errors.startDate}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="numberOfPeople">Number Of People</label>
            <input
              type="number"
              name="numberOfPeople"
              placeholder="Number Of People"
              value={formData.numberOfPeople}
              onChange={handleChange}
            />
            {errors.numberOfPeople && (
              <span className="error-message">{errors.numberOfPeople}</span>
            )}
          </div>
        </div>

        <div className="event-form-row">
          <div className="form-group">
            <label htmlFor="startTime">Start Time</label>
            <input
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
            />
            {errors.startTime && (
              <span className="error-message">{errors.startTime}</span>
            )}
          </div>
          <div className="form-group ">
            <label htmlFor="endTime">End Time</label>
            <input
              placeholder="time"
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
            />
            {errors.endTime && (
              <span className="error-message">{errors.endTime}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="additionalInfo">
            Is there any Additional Information you would like to add?
          </label>
          <textarea
            name="additionalInfo"
            value={formData.additionalInfo}
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p color="white">
              Thank you for your Event Request. We will reach out to you soon !
            </p>
            <button onClick={() => setShowSuccessModal(false)}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventRequest;
