import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import HeroSection from "./Components/HeroSection";
import Careers from "./Components/Careers";
import Footer from "./Components/Footer";
import "./App.css";
import ScrollToTop from "./Components/ScrollToTop";
import EventRequest from "./Components/EventRequest";
import Menu from "./Components/Menu/Menu";
import Map from "./Components/Map";

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <Map />
              </>
            }
          />
          <Route path="/events" element={<EventRequest />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/menu" element={<Menu />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
