import React from "react";
import Hero from "./Hero";
import Special from "./Special";

import Catlog from "./Catlog";

function Menu() {
  return (
    <div className="bg-[#0c0c0c] pb-20">
      <Hero />
      <Special />
      <Catlog />
    </div>
  );
}

export default Menu;
