import React from 'react';

const ExtrasSection = ({ title, description, extras }) => {
  return (
    <div className="mt-12 lg:mt-20 w-[94%] mx-auto flex flex-col gap-5 p-4 h-[320px] justify-center items-center lg:w-[1236px] bg-black">
      <h1 className="text-xl md:text-2xl">{title}</h1>
      <p className="text-[#939393] md:w-[410px] text-center">
        {description}
      </p>
      {title === "Sauces" && (
        <>
  <h1 className="text-xl md:text-2xl">Dry Rubs</h1>
  <p className="text-[#939393] md:w-[410px] text-center">
  Cajun, Jerk, Blackened, Lemon Pepper, Blazing
  </p>
        </>
      )}
      <div>
        <ul className="text-[#939393] list-disc flex flex-col justify-center items-center">
          {extras?.map((extra, index) => (
            <li key={index}>{extra}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ExtrasSection;
