import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "../css/Footer.css";
import LogoWhite from "../Images/LogoWhite.png"; // Import your image
import Instagram from "../Images/Vector.png";
import Facebook from "../Images/facebook.png";
import map from "../Images/FooterMap.png";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const form = useRef();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.(com|org|net|edu|gov)$/i;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate email on form submit
    if (!validateEmail(email)) {
      setIsEmailValid(false);
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    setIsEmailValid(true);
    setErrorMessage("");

    const templateParams = {
      user_email: email,
      to_name: "Gallop's Kitchen",
    };

    emailjs
      .send(
        "service_mmkocjg",
        "template_47xg9be",
        templateParams,
        "e-BS1zn4fKj6fHTPG"
      )
      .then(
        () => {
          setSuccessMessage("Thank you for subscribing!");
          setEmail("");
          setIsEmailValid(false);
          setShowSuccessModal(true);
        },
        (error) => {
          setErrorMessage("Failed to send, please try again.");
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <footer className="footer">
      <div className="footer-top">
        <div class="bg-image"></div>
        <div className="footer-title">
          <h4>We are</h4>
          <h1>Open</h1>
        </div>
        <div className="footer-timing">
          <div className="footer-hours">
            <div className="footer-hours-section">
              <h4>Monday</h4>
              <p>Closed</p>
            </div>
            <div className="footer-hours-section center">
              <h4>Tuesday-Saturday</h4>
              <p>11:30am-10pm</p>
            </div>
            <div className="footer-hours-section">
              <h4>Sunday</h4>
              <p>11:30am-9pm</p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-location">
          <img src={LogoWhite} alt="Logo" className="footer-logo" />
          <h4>Our Location</h4>

          <p> 240 North Liberty Street, <br />Powell, OH 43065</p>
          <button className="directions-button"
            onClick={() => window.open('https://www.google.com/maps/dir/?api=1&destination=240+North+Liberty+Street,+Powell,+OH+43065', '_blank')}

          >
            Directions
          </button>
        </div>
        <div className="footer-contact" style={{ height: 93 }}>
          <h4>Contact</h4>
          <p>(614) 396-7309</p>
          <p>Gallopspowell@gmail.com</p>
        </div>
        <div className="footer-newsletter" style={{ height: 93 }}>
          <h4>NewsLetter</h4>
          <p>Sign up for our newsletter & get exclusive offers and invites!</p>
          <form
            className="newsletter-input-group"
            ref={form}
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              placeholder="Your Mail"
              value={email}
              onChange={handleChange}
              required
            />
            <button type="submit">Submit</button>
          </form>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </div>
      </div>

      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p color="white">Thank you for subscribing!</p>
            <button onClick={() => setShowSuccessModal(false)}>OK</button>
          </div>
        </div>
      )}

      <div className="footer-separator"></div>
      <div className="footer-bottom">
        <div className="footer-copyright">
          <a
            href="https://peoples-consulting.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>© 2024 Peoples Consulting LLC</p>
          </a>
          <div className="social-media-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61554266552277&mibextid=LQQJ4d"
              target="_blank"
              aria-label="Facebook"
            >
              <img src={Facebook} alt="Facebook" class="social-icon" />
            </a>
            <a
              href="https://www.instagram.com/gallops_barkitchen/"
              target="_blank"
              aria-label="Instagram"
            >
              <img src={Instagram} alt="Instagram" class="social-icon" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
