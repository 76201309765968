import React, { useState } from "react";
import "../css/HeroSection.css";
import { Link, useNavigate } from "react-router-dom";
import row2Image1 from "../Images/BarImage.jpeg";
import row2Image2 from "../Images/KitchenImage.jpeg";
import orderOnlineImage from "../Images/OrderOnlineImage.png";
import reservationsImage from "../Images/Reservations.jpeg";
import GridImage1 from "../Images/GridImage1.png";
import GridImage2 from "../Images/GridImage2.png";
import GridImage3 from "../Images/GridImage3.jpeg";
import GridImage4 from "../Images/GridImage4.png";
import GridImage5 from "../Images/GridImage5.jpeg";
import GridImage6 from "../Images/GridImage6.jpeg";
import MenuImage1 from "../Images/MenuImage1.jpg";
import MenuImage2 from "../Images/MenuImage2.jpg";
import MenuImage3 from "../Images/MenuImage3.jpg";
import BottleOfferings from "../Images/BottleOfferings.jpeg";
import MenuImage4 from "../Images/MenuImage4.jpg";
import MenuImage5 from "../Images/MenuImage5.jpg";
import MenuImage6 from "../Images/MenuImage6.jpg";
import MainImage from "../Images/MainImage.jpeg";

const HeroSection = () => {
  const navigate = useNavigate();
  const [people, setPeople] = useState("");
  const [time, setTime] = useState("");

  const [date, setDate] = useState("");
  const [error, setError] = useState("");

  const handlePeopleChange = (e) => setPeople(e.target.value);
  const handleTimeChange = (e) => setTime(e.target.value);

  const handleFindTableClick = () => {
    // Clear form data
    setPeople("");
    setDate("");
    setTime("");

    // Redirect to the external link
    window.open(
      "https://www.opentable.com/restref/client/?restref=1178254&lang=en-US&ot_source=Restaurant%20website&corrid=66670a45-0851-4a6d-a0d2-2bf2c0fed88d",
      "_blank",
      "noopener noreferrer"
    );
  };

  const isValidDay = (day) => day >= 1 && day <= 31;
  const isValidMonth = (month) => month >= 1 && month <= 12;

  const handleChange = (e) => {
    let value = e.target.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, "");

    // Format the date as MM/DD/YYYY
    if (value.length > 2) {
      value = value.slice(0, 2) + "/" + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + "/" + value.slice(5);
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }

    // Validate month and day
    const [month, day] = value.split("/").map(Number);
    let errorMsg = "";

    if (value.length >= 5) {
      // Only validate when month and day are fully entered
      if (value.length === 5) {
        if (!isValidMonth(month)) {
          errorMsg = "Invalid month. Must be between 01 and 12.";
        }
      } else if (value.length > 5) {
        if (month && !isValidMonth(month)) {
          errorMsg = "Invalid month. Must be between 01 and 12.";
        } else if (day && !isValidDay(day)) {
          errorMsg = "Invalid day. Must be between 01 and 31.";
        }
      }
    }

    // Restrict further input if there's an error
    if (!errorMsg) {
      setDate(value);
    }
    setError(errorMsg);
  };

  const handleViewMenuClick = () => {
    navigate("/menu"); // Redirects to the menu screen
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleViewMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className="hero-section">
        <div className="top-image-section">
          <img
            src={MainImage}
            alt="Job Application"
            className="full-width-image"
          />

          <div className="hero-block">
            <div className="head-content">
              The Perfect Blend of Food and Fun
            </div>
          </div>
        </div>

        <div className="section">
          <div className="text-content">
            <div className="gallops-bar-head">Gallop's Bar & Kitchen</div>
            <div className="gallops-bar-para">
              Gallop's Bar & Kitchen is your ultimate game-day destination! With
              15+ large flat-screen TVs, an impressive selection of bourbons,
              craft cocktails, and over 20 draft beers on tap
              {isExpanded && (
                <span className="additional-text">
                  , we’re the perfect place to rally with friends, savor family
                  dinners, and cheer on your favorite teams in style.
                </span>
              )}
            </div>
            <button
              className="view-more text-white"
              onClick={handleViewMoreClick}
            >
              {" "}
              {isExpanded ? "View Less" : "View More"}
            </button>
          </div>
          <div className="image-content">
            <div className="image-content-1">
              <img
                src={row2Image1}
                alt="Bartender pouring drink"
                className="image image1"
              />
            </div>
            <div className="image-content-2">
              <img
                src={row2Image2}
                alt="Chef cooking in kitchen"
                className="image image2"
              />
            </div>
          </div>
        </div>

        <div className="outer-grid">
          <div className="image-grid">
            <div className="image-wrapper">
              <img src={GridImage1} alt="Image 1" />
            </div>
            <div className="image-wrapper">
              <img src={GridImage2} alt="Image 2" />
            </div>
            <div className="image-wrapper">
              <img src={GridImage3} alt="Image 3" />
            </div>
            <div className="image-wrapper">
              <img src={GridImage4} alt="Image 4" />
            </div>
            <div className="image-wrapper">
              <img src={GridImage5} alt="Image 5" />
            </div>
            <div className="image-wrapper">
              <img src={GridImage6} alt="Image 6" />
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center flex-wrap gap-4   p-4 lg:py-[40px] bg-[#121212]">
          <div>
            <img
              className="rounded-lg md:w-[380px] lg:w-[618px] lg:h-[418px]"
              src={orderOnlineImage}
              alt="Online Food Ordering"
            />
          </div>

          <div className="text-container ">
            <div className="text-white text-[40px]   mx-auto md:mx-0 mb-1">
              Order Online
            </div>
            <div className="mx-auto md:mx-0 text-container-para1">
              Pick Up Your Favorite Food
            </div>
            <div className="text-container-para2 mx-auto md:mx-0 ">
              Don't feel like cooking today? We got you covered.
            </div>
            <button
              className="text-white mx-auto md:mx-0"
              onClick={() =>
                window.open(
                  "https://www.toasttab.com/gallups-240-n-liberty-st-a",
                  "_blank",
                  "noopener noreferrer"
                )
              }
            >
              Order now
            </button>
          </div>
        </div>

        <div className="menu-grid">
          <div className="menu-row">
            <div className="menu-head">
              Authentic Creole
              <br />
              And Appetizers
              <div className="menu-foot ">
                <button className="text-white" onClick={handleViewMenuClick}>
                  View Menu
                </button>
              </div>
            </div>
            <div className="image-right">
              <div className="menu-image">
                <div className="menu-name">Sandwiches & Wraps</div>
                <div className="menu-link ">
                  <Link
                    to="/menu"
                    style={{
                      color: "white",
                      fontFamily: '"Plus Jakarta Sans", sans-serif',
                    }}
                  >
                    View Menu
                  </Link>
                </div>
                <img src={MenuImage1} alt="Image 1" />
              </div>
              <div className="menu-image">
                <div className="menu-name">Red Wines</div>
                <img src={MenuImage2} alt="Image 2" />
              </div>
            </div>
          </div>
          <div className="menu-row image-grid">
            <div className="menu-image">
              <div className="menu-name">Cocktails</div>
              <img src={MenuImage3} alt="Image 2" />
            </div>
            <div className="menu-image">
              <div className="menu-name">White Wines</div>
              <img src={MenuImage4} alt="Image 3" />
            </div>

            <div className="menu-image">
              <div className="menu-name">Starters</div>
              <img src={MenuImage5} alt="Image 4" />
            </div>
            <div className="menu-image">
              <div className="menu-name">Bottle Offerings</div>
              <img src={MenuImage6} alt="Image 1" />
            </div>
          </div>
        </div>
        <div className="reservations-container">
          <div className="reservations-image-container">
            <img src={reservationsImage} alt="Reservations" />
          </div>

          <div className="reservations-content">
            <div className="reservations-header">Reservations</div>
            <div className="reservations-para">
              Call Us at (614) 396-7309 or book a table through open table:
            </div>
            <form>
              <div className="form-group">
                <label htmlFor="people"></label>
                <select
                  id="people"
                  aria-placeholder="Number of People"
                  name="people"
                  className="people"
                  value={people}
                  onChange={handlePeopleChange}
                >
                  <option value="" disabled selected hidden>
                    People
                  </option>
                  {[...Array(10)].map((_, i) => (
                    <option key={i} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="date"></label>
                <input
                  type="datetext"
                  id="date"
                  name="date"
                  placeholder="MM/DD/YYYY"
                  className="date"
                  value={date}
                  onChange={handleChange}
                  maxLength="10"
                />
                {error && (
                  <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="time"></label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  placeholder="Time"
                  className="time"
                  value={time}
                  onChange={handleTimeChange}
                />
              </div>
              <button
                type="button"
                className="text-white"
                onClick={handleFindTableClick}
              >
                Find a Table
              </button>
            </form>
          </div>
        </div>
      </div>
 
    </>
  );
};

export default HeroSection;
