import React, { useState } from "react";
import "../css/Careers.css";
import emailjs from 'emailjs-com';
import CareerImage from '../Images/CareersImage.jpeg';

const Careers = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    mobileNumber: "",
    email: "",
    experience: "",
    applyingFor: [],
    coverLetter: "",
    resume: null,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    mobileNumber: "",
    email: "",
    experience: "",
    applyingFor: "",
  });
  

  const validateEmailAddress = (email) => {
    const emailRegex = /^[^\s@]+@(gmail\.com|outlook\.com|yahoo\.com|hotmail\.com)$/i;
  return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "tel"){
      let input = e.target.value.replace(/\D/g, '');
      if (input.startsWith('1')) {
        input = input.substring(1); // Remove the leading '1' since we'll add it later
      }
  
      let formattedNumber = '';
      const cursorPosition = e.target.selectionStart; // Get the current cursor position
      const lastChar = value[cursorPosition - 1]; // Get the last character before the cursor
  
      // Format the number
      if (input.length > 0) {
        formattedNumber += '+1 '; // Add country code
  
        if (input.length > 0) {
          formattedNumber += '(' + input.slice(0, 3); // Area code
  
          if (input.length > 3) {
            formattedNumber += ') ' + input.slice(3, 6); // First three digits
          }
          if (input.length >= 6) {
            formattedNumber += '-' + input.slice(6, 10); // Last four digits
          }
        }
      }
  
      // Handle backspace or delete
      if (lastChar === ')' || lastChar === '-' || lastChar === ' ') {
        formattedNumber = formattedNumber.slice(0, -1); // Remove last character if backspace is pressed
      }
  
      setFormData({
        ...formData,
        mobileNumber: formattedNumber.trim(),
      });
    }
    else if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        applyingFor: checked
          ? [...prev.applyingFor, value]
          : prev.applyingFor.filter((item) => item !== value),
      }));
    } else if (type === "file") {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } 
    else if (type === "email"){
      const inputEmail = e.target.value;
      
      setFormData({ ...formData, [name]: inputEmail });
  
      if (validateEmailAddress(inputEmail)) {
        setIsEmailValid(true);
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
      } else {
        setIsEmailValid(false);
        setErrors((prevErrors) => ({ ...prevErrors, email: "Please enter a valid email address with a supported domain." }));
      }
  
      setSuccessMessage(''); 
    }
    else {
      setFormData({ ...formData, [name]: value });
    }


    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    let hasErrors = false;
    let newErrors = {};

    const phoneNumberDigits = formData.mobileNumber.replace(/\D/g, '');
  
    if (!formData.firstName) {
      hasErrors = true;
      newErrors.firstName = "Please enter your Name";
    }
  
    if (!formData.mobileNumber) {
      hasErrors = true;
      newErrors.mobileNumber = "Please enter your Mobile Number";
    }else if (phoneNumberDigits.length <= 10) {
      hasErrors = true;
      newErrors.mobileNumber = "Please enter a valid 10-digit Mobile Number";
    }
  
    if (!formData.email) {
      hasErrors = true;
      newErrors.email = "Please enter your Email Address";
    }else if (!validateEmailAddress(formData.email)) {
      hasErrors = true;
      newErrors.email = "Please enter a valid email address with a supported domain.";
    }
  
    if (!formData.experience) {
      hasErrors = true;
      newErrors.experience = "Please enter your Experience";
    }
    if (formData.applyingFor.length === 0) {
      hasErrors = true;
      newErrors.applyingFor = "Please select at least one job position";
    }
  
    setErrors(newErrors);
  
    if (hasErrors) return;

    const serviceID = 'service_mmkocjg'; 
  const templateID = 'template_kjpw5wb'; 
  const userID = 'e-BS1zn4fKj6fHTPG'; 
  
  const templateParams = {
    firstName: formData.firstName,
    mobileNumber: formData.mobileNumber,
    email: formData.email,
    experience: formData.experience,
    applyingFor: formData.applyingFor.join(', '),
    coverLetter: formData.coverLetter,
    resume: formData.resume ? formData.resume.name : '', 
  };

  emailjs.send(serviceID, templateID, templateParams, userID)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSuccessMessage('Your application has been submitted successfully!');

      // Reset formData to initial values
      setFormData({
        firstName: "",
        mobileNumber: "",
        email: "",
        experience: "",
        applyingFor: [],
        coverLetter: "",
        resume: null,
      });
      
      // Clear error and success messages
      setErrorMessage('');
      setSuccessMessage('');
      setShowSuccessModal(true);
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setErrorMessage('There was an issue submitting your application. Please try again later.');
    });

  
    console.log(formData);
  };
  

  return (
    <div className="careers-container">
      <div className="top-image-section">
        <img src={CareerImage} alt="Job Application" className="full-width-image" />
        <div className="text-overlay">
          <h1>Job Application Form</h1>
          <p><br />Alternatively, if you have questions you can call us at (614) 396-7309</p>
        </div>
      </div>

      <form className="career-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="firstName">Enter your Name</label>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your full name"
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && <span className="error-message">{errors.firstName}</span>}
          </div>
        </div>
        <div className="form-row">
        <div className="form-group half-width">
          <label htmlFor="mobileNumber">Enter your Mobile Number</label>
          <input
            type="tel"
            name="mobileNumber"
            placeholder="+1 (555) 555-5555"
            value={formData.mobileNumber}
            onChange={handleChange}
          />
          {errors.mobileNumber && <span className="error-message">{errors.mobileNumber}</span>}
        </div>
        
        <div className="form-group half-width">
          <label htmlFor="email">Enter Email Address</label>
          <input
            type="email"
            name="email"
            placeholder="Example@email.com"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>
        </div>

        <div className="form-row">
          <div className="form-group third-width">
            <label htmlFor="experience">Service  Industry  Experience ... (ex 3 years serving)</label>
            <input
              type="number"
              name="experience"
              placeholder="Enter  Number"
              value={formData.experience}
              onChange={handleChange}
            />
            {errors.experience && <span className="error-message">{errors.experience}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Applying For:</label>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  name="applyingFor"
                  value="Dishwasher"
                  checked={formData.applyingFor.includes("Dishwasher")}
                  onChange={handleChange}
                />
                Dishwasher
              </label>
              <label>
                <input
                  type="checkbox"
                  name="applyingFor"
                  value="Line Cook"
                  checked={formData.applyingFor.includes("Line Cook")}
                  onChange={handleChange}
                />
                Line Cook
              </label>
              <label>
                <input
                  type="checkbox"
                  name="applyingFor"
                  value="Pizza Maker"
                  checked={formData.applyingFor.includes("Pizza Maker")}
                  onChange={handleChange}
                />
                Pizza Maker
              </label>
              <label>
                <input
                  type="checkbox"
                  name="applyingFor"
                  value="Prep Cook"
                  checked={formData.applyingFor.includes("Prep Cook")}
                  onChange={handleChange}
                />
                Prep Cook
              </label>
              <label>
                <input
                  type="checkbox"
                  name="applyingFor"
                  value="Server"
                  checked={formData.applyingFor.includes("Server")}
                  onChange={handleChange}
                />
                Server
              </label>
            </div>
            {errors.applyingFor && <span className="error-message">{errors.applyingFor}</span>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="coverLetter">Cover Letter (Optional)</label>
            <textarea
              name="coverLetter"
              placeholder="Type something..."
              value={formData.coverLetter}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <div className="form-row">
          <div className="form-group">
            <label htmlFor="resume">Upload Resume (Optional)</label>
            <input
              type="file"
              name="resume"
              onChange={handleChange}
            />
          </div>
        </div> */}

        <div className="form-row">
          <button type="submit" className="submit-button">Submit</button>
        </div>
      </form>
      {showSuccessModal && (
            <div className="modal-overlay">
              <div className="modal-content">
                <p color='white'>Thank you for Applying. We will reach out to you soon !</p>
                <button onClick={() => setShowSuccessModal(false)}>OK</button>
              </div>
            </div>
          )}
    </div>
  );
};

export default Careers;
