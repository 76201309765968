import React from "react";

const MenuSection = ({ items, images, title, titleNote, rowRerverse }) => {
  return (
    <div
      className={`flex flex-wrap justify-center pt-20 ${
        rowRerverse ? "flex-row-reverse " : ""
      }gap-12`}
    >
      <div
        className={`${
          rowRerverse
            ? "border-l border-[#545454] p-4 flex flex-col gap-10"
            : "lg:border-l lg:ps-[32px] lg:border-[#545454] p-4 flex flex-col gap-10"
        }`}
      >
        <h1 className="text-2xl md:text-3xl lg:text-6xl">
          {title} <span className="text-sm">{titleNote}</span>
        </h1>
        {title === "Burgers $16" && (
          <p className="text-[#939393] md:w-[350px]">
            9oz hand patted before grilled, comes with mayonnaise, lettuce,
            tomato, red onion
          </p>
        )}
        <div className="flex flex-col gap-3 ">
          {items.map((item, index) => (
            <div key={index} className="flex flex-col gap-3">
              <div className="flex justify-between">
                <h3 className="text-2xl"> {item.foodName}</h3>
                <p className="text-2xl">{item.price}</p>
              </div>
              <p className="text-[#939393] lg:w-[600px]">{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-5">
        {images.map((data, i) => (
          <div key={i} className="flex items-center gap-4">
            <img
              className="lg:w-[562px] lg:h-[550px]"
              src={data.img}
              alt={data.alt || `Image ${i + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSection;
