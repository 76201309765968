import React from "react";
import MenuSection from "./MenuSection";
import {
  burgerimages,
  burgerItems,
  entryimages,
  entryItems,
  pizaimages,
  pizaItems,
  regularExtras,
  regularItems,
  regurlarimages,
  saladimages,
  SaladItems,
  sandwichimages,
  sandwichItems,
  sidesImg,
  sidesItems,
} from "./catlogmenu";
import ExtrasSection from "./Extra";

function Catlog() {
  return (
    <>
      <h1 className="text-2xl md:text-3xl lg:text-4xl text-center pt-10 lg:pt-[95px] pb-20">
        Regular Servings
      </h1>
      <MenuSection
        items={regularItems}
        images={regurlarimages}
        title="Starters"
      />
      <ExtrasSection
        title="Sauces"
        description="Teriyaki, BBQ, Carolina BBQ, Parmesan Ranch
Garlic Parmesan, Mild, Mild Garlic Parmesan,  Pineapple Chili, Spicy BBQ, Buffalo, Mustang 
Hot, Finish Line, Sriracha Lime, XHot"
        
      />
      <MenuSection
        items={SaladItems}
        images={saladimages}
        title="Salads $15"
        rowRerverse
      />
      <ExtrasSection
        title="Extras*"
        description="Ranch, Blue Cheese, Caesar, Balsamic, French, Italian
        Mustang Maple, Honey Mustard, Chipotle Ranch
        Cuban Mustard, 1,000 Island"
        extras={regularExtras}
      />
      <MenuSection
        items={burgerItems}
        images={burgerimages}
        title="Burgers $16"
        titleNote="w/fries"
      />
      <ExtrasSection
        title="Extras*"
        description="  American, Swiss, Cheddar, Provolone, Fresh Mozzarella Pepperjack, Feta, Ricotta, Monterey Jack Cheddar
        Blue Cheese Crumbles, Parmesan"
      />

      <h1 className="text-2xl md:text-3xl lg:text-4xl text-center pt-10 lg:pt-[95px] ">
        PIZZA
      </h1>
      <p className="text-center mx-auto text-[#939393] pt-3 md:w-[550px] ">
        Pizzas start with mozzarella and provolone cheese on top of our homemade
        pizza dough
      </p>
      <ul className="text-[#939393]  flex flex-col justify-center items-center pt-10 pb-20">
        <li>18 inch $28 (LG) - 14 inch $21 (M)</li>
        <li>Additional topping $2.75(LG) – $2.25(M)</li>
        <li>Specialty Pizzas $32 (LG) $25(M)</li>
      </ul>
      <MenuSection items={pizaItems} images={pizaimages} rowRerverse />
      <ExtrasSection
        title="Extras*"
        description="Pepperoni, Italian Sausage, Bacon, Chicken
Ham, Capicola, Salami, Meatballs
Tomato, Red Onion, Caramelized Onion
Mushrooms, Fresh Garlic, Roasted Garlic
Bell Peppers, Sauteed Spinach, Jalapeno
Banana Peppers, Black Olives, Pineapple
Roasted Red Peppers, Artichokes"
      />
      <MenuSection title="Entrée" items={entryItems} images={entryimages} />
      <MenuSection
        title="Sandwiches "
        titleNote="w/fries"
        items={sandwichItems}
        images={sandwichimages}
        rowRerverse
      />
      <MenuSection title="Sides" items={sidesItems} images={sidesImg} />
      <ExtrasSection
        title="Extras*"
        description="Side substitutions is $1 and split plates is $2 upcharge Meat, poultry, seafood consumed raw or undercooked increases your risk of foodborne illness"
      />
    </>
  );
}

export default Catlog;
