import regurlarimg_1 from "../../Images/rinds.png";
import regurlarimg_2 from "../../Images/starters-food.png";
import regurlarimg_3 from "../../Images/starters-food2.png";
import saladimg from "../../Images/salad.png";
import burgerimg from "../../Images/burger-food.png";
import piza from "../../Images/pizza.png";
import entry from "../../Images/entree.png";
import sandwichimg_1 from "../../Images/sandwich-img.png";
import sandwichimg_2 from "../../Images/sandwich-img2.png";
import sandwichimg_3 from "../../Images/sandwich-img3.png";
import sides from "../../Images/sides.png";
export const regularItems = [
  {
    foodName: "Potato Skins ",
    price: "$10",
    description:
      "House skins topped with Monterey jack cheddar, bacon, served with green onion sour cream.",
  },
  {
    foodName: "White Cheddar Curds ",
    price: "$9",
    description:
      "Crispy curds sprinkled with parmesan and served with marinara.",
  },
  {
    foodName: "Fried Pickle Spears",
    price: "$9",
    description:
      "Hand battered crispy pickle spears, served with chipotle ranch.",
  },
  {
    foodName: "Pierogies ",
    price: "$9",
    description:
      "Potato and cheese pierogies sauteed with caramelized onions served with green onion sour cream.",
  },
  {
    foodName: "Loaded Fries",
    price: "$12",
    description:
      "Topped with Monterey jack cheddar, bacon, ranch, mustang sauce, served with green onion sour cream.",
  },
  {
    foodName: "Fried Mushrooms ",
    price: "$9",
    description:
      "Hand battered fresh mushrooms fried crispy served with chipotle ranch.",
  },
  {
    foodName: "Meatballs",
    price: "$12",
    description:
      "Homemade beef meatballs in tomato herb sauce sprinkled with parmesan.",
  },
  {
    foodName: "Chicken Quesadilla",
    price: "$12",
    description:
      "Tortilla filled with Monterey jack cheddar, Pico de Gallo, grilled chicken served with green onion sour cream.",
  },
  {
    foodName: "Nachos",
    price: "$12",
    description:
      "In house fried tortilla chips topped with Monterey jack cheddar, grilled chicken, Pico de Gallo, jalapenos green onion sour cream.",
  },
  {
    foodName: "Pork Rinds",
    price: "$9",
    description:
      "Fried in house pork skins seasoned with smoky spices served with ranch and hot sauce.",
  },
  {
    foodName: "Avocado Hummus",
    price: "$11",
    description:
      "Daily made citrus and avocado infused hummus served with grilled pita and crisp vegetables",
  },
  {
    foodName: "Cajun Shrimp",
    price: "$14",
    description:
      "Cajun spiced grilled shrimp drizzled with our finish line sauce.",
  },
  {
    foodName: "Coconut Shrimp",
    price: "$14",
    description:
      "Large shrimp coconut breaded by hand served with pineapple chili.",
  },
  {
    foodName: "Beer Battered Shrimp",
    price: "$14",
    description:
      "Large shrimp deep fried golden brown served with cocktail sauce.",
  },
  {
    foodName: "Boneless Wings  ",
    price: " 5/ $10  10/$18",
    description:
      "Hand battered chicken chunks tossed in your choice of sauce served with celery and dressing.",
  },
  {
    foodName: "Bone-in Wings    ",
    price: " 5/ $11  10/$20",
    description:
      "Lightly breaded jumbo wings tossed in your sauce of choosing served with celery and dressing.   ",
  },
];
export const regurlarimages = [
  { img: regurlarimg_1 },
  { img: regurlarimg_2 },
  { img: regurlarimg_3 },
];
export const regularExtras = [
  "Cajun, Jerk",
  "Blackened",
  "Lemon Pepper",
  "Blazing  ",
];
export const SaladItems = [
  {
    foodName: "House Salad ",
    price: "$15",
    description:
      "Mixed greens, carrots, tomato, red onion, parmesan cheese, roasted pepitas, grilled chicken served with your choice of dressing or vinaigrette.",
  },
  {
    foodName: "Giddy Up & Go",
    price: "$15",
    description:
      "Mixed greens, Monterey jack cheddar, tomato, bacon, avocado and fried chicken tossed in mustang sauce served with ranch dressing.",
  },
  {
    foodName: "Chicken Ceasar",
    price: "$15",
    description:
      "Romaine tossed in Ceasar dressing, parmesan cheese, croutons topped with grilled chicken.",
  },
  {
    foodName: "*Italian* ",
    price: "$15",
    description:
      "Mixed greens, mozzarella, provolone, tomato, red onion, banana peppers, ham, capicola, salami, pepperoni, and croutons served with Italian vinaigrette.",
  },
  {
    foodName: "Black & Blue",
    price: "$15",
    description:
      "Mixed greens, blue cheese crumbles, carrots, red onion, apples, candied blackened walnuts, blackened grilled chicken served with mustang maple.",
  },
  {
    foodName: "Mediterranean",
    price: "$15",
    description:
      "Mixed greens, feta, carrots, tomato, red onion, chickpeas, artichoke hearts, roasted red peppers, grilled chicken served with balsamic vinaigrette.",
  },
];
export const saladimages = [{ img: saladimg }];

export const burgerItems = [
  {
    foodName: "Gallop",
    description: "Fries’ sauce, American cheese, pickles.",
  },
  {
    foodName: "*Bacon Deluxe*",

    description: "Bacon, bacon jam, Monterey jack cheddar cheese.",
  },
  {
    foodName: "Rodeo",

    description: "BBQ sauce, bacon, cheddar cheese, onion straws.",
  },
  {
    foodName: "Black & Blue",

    description: "Cajon seasoning, bacon, blue cheese dressing.",
  },
  {
    foodName: "Sweet Heat",
    description: "Jalapenos, mustang sauce, pepperjack cheese.",
  },
  {
    foodName: "Wild Mushroom",

    description: "Sauteed mushrooms, Swiss cheese.",
  },
  {
    foodName: "Starting Lineup",

    description: "Bacon, ham, provolone cheese, sunny side up egg, avocado.",
  },
  {
    foodName: "Pizza",

    description:
      "Pepperoni, Italian sausage, tomato herb sauce, fresh mozzarella.",
  },
  {
    foodName: "Roasted Garlic",

    description: "Roasted garlic aioli, garlic butter, parmesan cheese.",
  },
];
export const burgerimages = [{ img: burgerimg }];
export const pizaimages = [{ img: piza }];
export const pizaItems = [
  {
    foodName: "Meats",

    description: "Tomato herb sauce, pepperoni, ham, sausage, bacon.",
  },
  {
    foodName: "Veggie",

    description:
      "Tomato herb sauce, mushroom, onion, bell pepper, banana pepper, tomato.",
  },
  {
    foodName: "*Works*",

    description:
      "Tomato herb sauce, pepperoni, sausage, bell pepper, onion, mushroom.",
  },
  {
    foodName: "Margherita",

    description: "Tomato herb sauce, tomatoes, fresh mozzarella, fresh basil.",
  },
  {
    foodName: "Hawaiian",

    description: "Ham, pineapple, pineapple chili, cinnamon.",
  },
  {
    foodName: "Garlic Mushroom",

    description: "Garlic butter, garlic, mushroom, parmesan.",
  },
  {
    foodName: "BLT",

    description: "Buffalo sauce, chicken, tomato, onion, banana pepper.",
  },
  {
    foodName: "Chicken Bacon Ranch",

    description: "Chicken, bacon, red onion, ranch.",
  },
  {
    foodName: "BBQ Pork",

    description: "BBQ sauce, pulled pork, bacon, red onion.",
  },
  {
    foodName: "Blazing",

    description:
      "Finish line sauce, pepperoni, banana pepper, jalapeno, blazing dust, green onion.",
  },
];
export const entryItems = [
  {
    foodName: "Chicken Tender Platter",
    price: "$18",
    description:
      "Fresh juicy chicken tenderloins hand battered and fried crispy, comes with slaw, fries, and a choice of sauce.",
  },
  {
    foodName: "Fish Platter",
    price: "$18",
    description:
      "Hand battered haddock fish deep fried, comes with slaw, fries, tartar sauce, and lemon wedge.",
  },
  {
    foodName: "Chicken & Waffles",
    price: "$18",
    description:
      "A house-made waffle topped with chicken tenders, bacon, mustang sauce, and maple syrup garnished with green onions, served with sweet potato fries.",
  },
  {
    foodName: "Meatball Mac",
    price: "$19",
    description:
      "Italian macaroni and cheese topped with our homemade meatballs, tomato herb sauce, garlic bread.",
  },
  {
    foodName: "Salmon Dinner",
    price: "$20",
    description: "Blackened salmon, grilled lemon, slaw, garlic ginger rice.",
  },
  {
    foodName: "Pork Chop Dinner",
    price: "$20",
    description: "BBQ glazed grilled pork chops, baked beans, mac & cheese.",
  },
  {
    foodName: "Shrimp Platter",
    price: "$20",
    description:
      "The choice of shrimp with accommodating sauce comes with slaw, fries..",
  },
  {
    foodName: "*Cajun Chicken Pasta*",
    price: "$19",
    description:
      "Onion, bell pepper, tomato, grilled chicken sauteed in garlic butter, covered in a Cajun cream sauce served with garlic bread.",
  },
];
export const entryimages = [{ img: entry }];
export const sandwichItems = [
  {
    foodName: "Cheese Melt",
    price: "$13",
    description: "American and provolone melted between grilled bread.",
  },
  {
    foodName: "Ham & Turkey Wrap ",
    price: "$15",
    description:
      "Mayonnaise, lettuce, tomato, bacon, Monterey jack cheddar, ham, turkey in a grilled tortilla.",
  },
  {
    foodName: "BLT",
    price: "$14",
    description: "Butter, bacon, tomato, mayo, mixed greens.",
  },
  {
    foodName: "Stallion ",
    price: "$15",
    description:
      "Ham, capicola ham, hard salami, pepperoni, provolone, lettuce, tomato, red onion, banana peppers, Italian vinaigrette on a grilled bun.",
  },
  {
    foodName: "*Philly*",
    price: "$15",
    description:
      "Sliced ribeye, caramelized onion, grilled peppers, provolone on a grilled bun.",
  },
  {
    foodName: "BBQ Pulled Pork ",
    price: "$15",
    description:
      "Seasoned pork, BBQ sauce, cheddar, onion straws on a grilled bun.",
  },
  {
    foodName: "Reuben",
    price: "$15",
    description:
      "Steamed Corned beef, sauerkraut, 1,000 Island, Swiss on grilled Rye bread.",
  },
  {
    foodName: "Buffalo Chicken Wrap",
    price: "$15",
    description:
      "Fried chicken, buffalo sauce, Monterey jack cheddar, lettuce, tomato, ranch in a grilled tortilla.",
  },
  {
    foodName: "Chicken Ceasar Wrap",
    price: "$15",
    description:
      "Grilled chicken, romaine lettuce, Caesar, parmesan in a grilled tortilla.",
  },
  {
    foodName: "Fish Sandwich",
    price: "$15",
    description:
      "Hand battered haddock, tartar sauce, lettuce, onion on a grilled bun.",
  },
  {
    foodName: "Classic Grilled Chicken",
    price: "$15",
    description:
      "Grilled chicken breast, mayonnaise, lettuce, tomato, onion on a grilled bun.",
  },
  {
    foodName: "Meatball Melt",
    price: "$15",
    description:
      "Homemade meatballs, tomato herb sauce, fresh mozzarella and provolone melted between grilled bread.",
  },
  {
    foodName: "Cuban",
    price: "$15",
    description:
      "Roasted fall off the bone pork, pickled cucumbers, ham, Cuban mustard, Swiss melted between grilled bread.",
  },
  {
    foodName: "Veggie Wrap",
    price: "$14",
    description:
      "Sauteed mushrooms, spinach, avocado hummus, feta, carrots, red onion, tomatoes, balsamic in a grilled tortilla.",
  },
  {
    foodName: "TCB Melt ",
    price: "$14",
    description:
      "Roasted turkey, tomatoes, honey mustard, bacon, cheddar melted between grilled bread.",
  },
  {
    foodName: "Sausage Melt   ",
    price: "$15",
    description:
      "Grilled Italian sausage, bell peppers, caramelized onions, honey mustard, provolone melted between grilled bread. ",
  },
];
export const sandwichimages = [
  { img: sandwichimg_1 },
  { img: sandwichimg_2 },
  { img: sandwichimg_3 },
];

export const sidesItems = [
  {
    foodName: "Fries",
    price: "$4",
  },
  {
    foodName: "Tator Tots",
    price: "$4",
  },
  {
    foodName: "House Salad",
    price: "$5",
  },
  {
    foodName: "Onion Straws",
    price: "$4",
  },
  {
    foodName: "Mac & Cheese",
    price: "$4",
  },
  {
    foodName: "Baked Beans",
    price: "$4",
  },
  {
    foodName: "Sweet Potato Fries",
    price: "$4",
  },
  {
    foodName: "Veggie of the Day",
    price: "$5",
  },
  {
    foodName: "Ceasar Salad",
    price: "$5",
  },
  {
    foodName: "Garlic Bread",
    price: "$4",
  },
  {
    foodName: "Garlic Ginger Rice",
    price: "$4",
  },
  {
    foodName: "Tequila Lime Slaw",
    price: "$4",
  },
];
export const sidesImg = [{img:sides}]