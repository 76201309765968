import React from 'react'
import '../css/Footer.css';
import map from '../Images/FooterMap.png';
function Map() {
  return (
    <div className='footer-map'>
    <a href="https://www.google.com/maps/place/240+North+Liberty+Street,+Powell,+OH+43065" target="_blank" rel="noopener noreferrer">
      <img src={map} alt='Map' />
    </a>
  </div>
  )
}

export default Map