import React from "react";
import "../../css/Menu.css";
import bg from "../../Images/menu-bg.png";
function Hero() {
  return (
    <>
      <div className="top-image-section ">
        <img src={bg} alt="Menu Page Image" className="full-width-image" />
        <div className="text-overlay">
          <h1>Our Menu</h1>
          <p>
            <br />
            WE ARE A LOCAL, FAMILY-OWNED BUSINESS. WE TAKE PRIDE IN SERVING BOLD, GLOBAL  FLAVORS TO OUR LOCAL COMMUNITY AND VISITORS PROVIDING OPPORTUNITIES TO CREATE SHARED MEMORIES WITH REMARKABLE DINING EXPERIENCE IN OUR COMFORTABLE AND RELAXED ATMOSPHERE CENTERED around best FOOD AND GREAT SERVICE.
            WE TREAT OUR GUESTS LIKE FAMILY AND PROVIDE A ONE-OF-A-KIND DINING EXPERIENCE BOUND TO SATISFY YOUR HANKERING.
            <br/>
            WE SOURCE FRESH MEATS, VEGETABLES AND INGREDIENTS FROM LOCAL COMPANIES AND FARMERS SUPPORTING LOCAL, WHILE GIVING BACK TO OUR COMMUNITY.
Thank you for all your support!
          </p>
        </div>
      </div>
    </>
  );
}

export default Hero;
