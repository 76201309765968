import React, { useState } from 'react';
import '../css/Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../Images/LogoWhite.png';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img src={Logo} alt="Restaurant Logo" className="logo-image" />
      </Link>
      <div className={`navbar-menu ${isMenuOpen ? 'open' : ''}`}>
        <Link to="/menu" onClick={closeMenu}
        className={location.pathname === '/menu' ? 'active' : ''}>Menu</Link>
        <a href="https://www.toasttab.com/gallups-240-n-liberty-st-a/giftcards" onClick={closeMenu} target="_blank" rel="noopener noreferrer">Gift Cards</a>
        <a href="https://www.toasttab.com/gallups-240-n-liberty-st-a" onClick={closeMenu} target="_blank" rel="noopener noreferrer">Order</a>
        <Link to="/events" onClick={closeMenu}
        className={location.pathname === '/events' ? 'active' : ''}>Events</Link>
        <Link to="/careers" onClick={closeMenu}
        className={location.pathname === '/careers' ? 'active' : ''}>Careers</Link>
      </div>
      <div className="nav-buttons">
        <button className="reserve-button" 
        onClick={() => window.open('https://www.opentable.com/restref/client/?restref=1178254&lang=en-US&ot_source=Restaurant%20website&corrid=66670a45-0851-4a6d-a0d2-2bf2c0fed88d', '_blank', 'noopener noreferrer')}
        >Reserve</button>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          aria-label="Toggle navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            className="bi"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
